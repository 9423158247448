<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push({name: 'SessionIndex'})"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.user-profile')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" v-if="!loadingState">
      <div class="avatar-container">
        <label>
          <input type="file" accept="image/*" ref="avatar" @change="actionChangeAvatar" hidden/>
          <avatar :value="data.profile_photo_tn" :initials="initials"></avatar>
        </label>
        <div class="avatar-delete-btn" @click="actionDeleteAvatar" v-if="data.profile_photo_tn !== null">
          <ion-icon :icon="trashOutline"/>
        </div>
      </div>
      <ion-item>
        <ion-label v-text="__('interface.email')"></ion-label>
        <ion-note slot="end" v-text="data.email"></ion-note>
      </ion-item>
      <ion-item @click="$router.push({name: 'MyProfile/PersonalInformation'})">
        <ion-label v-text="__('interface.personal-information')"></ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
      </ion-item>
      <ion-item @click="$router.push({name: 'MyProfile/ChangePassword'})">
        <ion-label v-text="__('interface.change-password')"></ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
      </ion-item>
      <ion-item @click="$router.push({name: 'MyProfile/Privacy'})">
        <ion-label v-text="__('interface.privacy')"></ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
      </ion-item>
      <ion-item @click="$router.push({name: 'MyProfile/RestingHeartRate'})">
        <ion-label v-text="__('interface.resting-heart-rate')"></ion-label>
        <ion-badge v-if="showRhrBadge" color="danger" slot="end">!</ion-badge>
        <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
      </ion-item>
      <ion-item @click="$router.push({name: 'MyProfile/MaxHeartRate'})">
        <ion-label v-text="__('interface.max-heart-rate')"></ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" size="small"></ion-icon>
      </ion-item>
      <ion-button color="danger" expand="full" class="ion-margin-vertical" @click="actionLogout">
        {{ __('interface.logout') }}
      </ion-button>
      <ion-button color="primary" expand="full" class="ion-margin-vertical" @click="openSupport">
        <ion-icon :icon="helpCircleOutline" slot="start"></ion-icon>
        {{ __('interface.support') }}
      </ion-button>
    </ion-content>
    <ion-footer v-if="appEnv !== 'prod'">
      <ion-toolbar class="ion-text-center">
        <ion-text color="primary"><small>Currently using <strong>{{ appEnv }}</strong> app. Version <strong>{{appVersion}}</strong></small></ion-text>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {IonIcon, IonPage, loadingController, IonFooter, IonText} from '@ionic/vue';
import {Auth} from '@aws-amplify/auth';
import Avatar from '../../components/Avatar';
import AuthView from '@/views/AuthService';
import {chevronForwardOutline, trashOutline, helpCircleOutline} from 'ionicons/icons';
import {putImage} from '../../utils/imageHandler';
import {API} from '@/utils/apiWrapper';
import loadImage from 'blueimp-load-image'
import {showMessages} from '@/utils/showMessages'
import { PushNotifications } from '@capacitor/push-notifications';

export default {
  name: 'MyProfile',
  mixins: [AuthView],
  components: {
    IonPage,
    IonIcon,
    IonFooter,
    IonText,
    Avatar
  },
  computed: {
    initials() {
      return this.data.first_name.substring(0, 1) + this.data.last_name.substring(0, 1)
    },
    myProfileNotifications() {
      return this.$store.state.myProfileNotifications ?? 0;
    },
    showRhrBadge() {
      return this.myProfileNotifications.rhr ?? false
    },
    deviceId() {
      return this.$store.state.deviceId ?? null;
    }
  },
  data() {
    return {
      data: {},
      loading: null,
      loadingState: true,
      appEnv: process.env.VUE_APP_ENV_NAME,
      appVersion: process.env.VUE_APP_version
    }
  },
  methods: {
    async actionLogout() {
      this.loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await this.loading.present();
        if (this.deviceId) {
          await API.del('TheSpottApi', '/device', {
            queryStringParameters: {
              id: this.deviceId
            }
          })
        }

      await Auth.signOut().then(() => {
        PushNotifications.removeAllDeliveredNotifications()
        PushNotifications.removeAllListeners()
        this.$router.push('/login')
        this.$tracking.event({
          eventName: 'logout',
          category: 'auth'
        });
        this.$tracking.clearUser()
      }).catch(error => {
        console.error('Error signing out: ', error)
      }).finally(() => {
        this.loading.dismiss();
      });
    },
    async actionChangeAvatar(e) {
      try {
        const files = e.target.files

        if (files.length > 0) {
          const loading = await loadingController.create({
            message: this.__('interface.loading'),
          });
          await loading.present();

          await loadImage(
            files[0],
            (img, data) => {
              if (data.imageHead) {
                img.toBlob(blob => {
                  loadImage.replaceHead(blob, data.imageHead, async newBlob => {
                    const uploadedFile = await putImage(new File([newBlob], files[0].name), 'avatars')
                    await API.put('TheSpottApi', '/avatar-create', {
                      body: {
                        objectKey: uploadedFile
                      },
                    }).then(response => {
                      this.data.profile_photo_tn = response.data.thumbnailKey
                      this.$tracking.event({
                        eventName: 'photo-update',
                        category: 'profile'
                      });
                    })
                    .catch(async error => {
                      await showMessages(error.response.data.messages)
                    })
                    .finally(() => {
                      loading.dismiss();
                    })
                  })
                }, 'image/jpeg')
              }
            },
            {meta: true, canvas: true}
          )
        }
      } catch (error) {
        console.log(error)
      }
    },
    async actionDeleteAvatar() {
      this.loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await this.loading.present();

      await API.post('TheSpottApi', '/avatar-delete', {}).then(() => {
        this.data.profile_photo_tn = null
        this.$tracking.event({
          eventName: 'photo-delete',
          category: 'profile'
        });
      }).finally(() => {
        this.loading.dismiss();
      })
    },
    openSupport () {
      window.open('https://the-spott.freshdesk.com/support/solutions/80000372256','_system', 'location=yes');
    }
  },
  async ionViewWillEnter() {
    this.loadingState = true;
    this.loading = await loadingController.create({
      message: this.__('interface.loading'),
    });
    await this.loading.present();
  },
  async ionViewDidEnter() {
    this.data = await this.user();
    this.loadingState = false;
    await this.loading.dismiss();
  },
  setup() {
    return {
      chevronForwardOutline,
      trashOutline,
      helpCircleOutline
    }
  },
}
</script>

<style lang="scss">
.avatar-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  ion-avatar {
    height: 72px;
    width: 72px;

    .blank-logo {
      font-size: 24px;
    }
  }

  .avatar-delete-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(50% + 11px);
    top: calc(50% + 11px);
    width: 24px;
    height: 24px;
    background-color: var(--ion-color-danger);
    color: #FFF;
    border-radius: 50%;
  }
}
</style>